<template>
  <section id="contact">
    <span class="background-headline">Kontakt</span>
    <div>
      <div class="contact-data">
        <h2>Kontaktieren Sie mich gerne</h2>
        <div class="row">
          <div class="col">
            <div class="address-wrapper">
              <p>Maximilianstraße 9</p>
              <p>93142 Maxhütte-Haidhof</p>
              <div class="social-media-wrapper-desktop d-none">
                <a href=""><i class="fab fa-linkedin-in"></i></a>
                <a href=""><i class="fab fa-xing"></i></a>
                <a href=""><i class="fab fa-instagram"></i></a>
              </div>
            </div>
          </div>
          <div class="col contact-tel-mail">
            <div class="contact-tel-mail-wrapper">
              <a href="tel:+4994716009779">Tel.: 09471 / 6009779 </a> <br />
              <a href="tel:+4917624397359">Mobil: 0176 / 24397359</a> <br />
              <a href="mailto:krell@krell-steuer.de"
                >E-Mail: krell@krell-steuer.de</a
              >
            </div>
            <div class="social-media-wrapper-mobile d-none">
              <a href=""><i class="fab fa-linkedin-in"></i></a>
              <a href=""><i class="fab fa-xing"></i></a>
              <a href=""><i class="fab fa-instagram"></i></a>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-auto">
          <a href="https://goo.gl/maps/PqFun4qikrTPUo3n6" target="_blank"
            ><img class="map" src="/assets/Map.svg" alt="Anfahrt"
          /></a>
        </div>
        <div class="col d-flex align-items-center justify-content-center">
          <div class="contact-form" v-if="!send">
            <div class="row">
              <h2>Kontaktformular</h2>
              <div class="form-group col-sm">
                <div
                  class="wrap-input100 validate-input"
                  data-validate="Die Eingabe darf nicht leer sein!"
                  :class="{ 'alert-validate': $v.contactform.name.$error }"
                >
                  <input
                    :class="{ 'has-val': contactform.name }"
                    @blur="$v.contactform.name.$touch"
                    id="form-name"
                    class="input100"
                    type="text"
                    name="name"
                    v-model="contactform.name"
                  />
                  <span class="focus-input100" data-placeholder="Name"></span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="form-group col-sm">
                <div
                  class="wrap-input100 wrap-email-input validate-input"
                  data-validate="Die Eingabe darf nicht leer sein und muss dem Format: a@b.c entsprechen!"
                  :class="{ 'alert-validate': $v.contactform.email.$error }"
                >
                  <input
                    :class="{ 'has-val': contactform.email }"
                    @blur="$v.contactform.email.$touch"
                    id="form-email"
                    class="input100"
                    type="email"
                    name="email"
                    v-model="contactform.email"
                  />
                  <span class="focus-input100" data-placeholder="E-Mail"></span>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="form-group col-sm-12">
                <div
                  class="validate-input message-area"
                  :class="{ 'alert-validate': $v.contactform.message.$error }"
                  data-validate="Die Eingabe darf nicht leer sein!"
                >
                  <textarea
                    :class="{ 'has-val': contactform.message }"
                    @blur="$v.contactform.message.$touch"
                    id="form-msg"
                    class="form-msg input100"
                    name="msg"
                    v-model="contactform.message"
                  ></textarea>
                  <span
                    class="focus-input100"
                    data-placeholder="Nachricht"
                  ></span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="form-group col-sm-12 datenschutz-control-wrapper">
                <div>
                  <label
                    class="datenschutz-control validate-input"
                    data-validate="Sie müssen die Datenschutzerklärung akzeptieren!"
                    :class="{
                      'alert-validate': $v.contactform.datenschutzCheck.$error,
                    }"
                  >
                    <input
                      @blur="$v.contactform.datenschutzCheck.$touch"
                      id="contact-dsc"
                      type="checkbox"
                      class="contact-check input100"
                      name="datenschutz-check"
                      v-model="contactform.datenschutzCheck"
                    />
                    &nbsp;Hiermit bestätigen Sie unsere Datenschutzbestimmungen.
                    Mehr Informationen finden Sie unter
                    <a
                      class="datenschutz"
                      target="_blank"
                      href="etc/datenschutz/#kontaktformular"
                      >Datenschutzerklärung</a
                    >
                    gelesen und akzeptiert.
                  </label>
                </div>
              </div>
            </div>
            <div class="text-center send-wrapper">
              <div style="display: inline-block;">
                <button @click="sendMail" class="krell-btn">
                  <label>Absenden</label
                  ><lord-icon
                    @click.prevent
                    class="contact-send-icon"
                    target="button"
                    trigger="hover"
                    src="./assets/177-envelope-mail-send.json"
                  ></lord-icon>
                </button>
              </div>
            </div>
          </div>
          <div v-if="sendError">
            <div class="send-status-icon box-shadow text-center">
              <lord-icon
                target="div"
                trigger="hover"
                src="./assets/182-envelope-mail-cross.json"
              ></lord-icon>
              <p class="text-center">
                Die Nachricht konnte nicht versendet werden!
              </p>

              <button class="krell-btn" @click="resetForm">
                Erneut versuchen
              </button>
            </div>
          </div>
          <div v-if="sendSuccess">
            <div class="send-status-icon box-shadow text-center">
              <lord-icon
                target="div"
                trigger="hover"
                src="./assets/183-envelope-mail-approved.json"
              ></lord-icon>
              <p class="text-center">
                Die Nachricht wurde erfolgreich versendet!
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import { required, email } from "vuelidate/lib/validators";
export default {
  name: "ContentTypeContact",
  data() {
    return {
      contactform: {
        datenschutzCheck: false,
        message: "",
        name: "",
        email: "",
      },
      send: false,
      sendSuccess: false,
      sendError: false,
    };
  },
  validations() {
    return {
      contactform: {
        name: { required },
        email: { required, email },
        message: { required },
        datenschutzCheck: { accepted: (val) => val === true },
      },
    };
  },
  methods: {
    sendMail: async function(event) {
      event.preventDefault();
      this.$v.contactform.$touch();
      this.sendSuccess = false;
      this.sendError = false;
      if (
        !this.$v.contactform.name.$error &&
        !this.$v.contactform.email.$error &&
        !this.$v.contactform.message.$error &&
        !this.$v.contactform.datenschutzCheck.$error
      ) {
        this.send = true;
        await axios
          .post("/api/mail", this.contactform)
          .then((response) => {
            if (response.data.status === "success") {
              this.sendSuccess = true;
              this.sendError = false;
            } else if (response.data.status === "failed") {
              this.sendError = true;
              this.sendSuccess = false;
            }
          })
          .catch(() => {
            this.sendError = true;
            this.sendSuccess = false;
          });
      }
    },
    resetForm: function() {
      this.sendSuccess = false;
      this.sendError = false;
      this.send = false;
      this.contactform = {
        datenschutzCheck: false,
        message: "",
        name: "",
        email: "",
      };
    },
  },
};
</script>

<style>
.contact-data {
  padding-top: 20rem;
  padding-bottom: 5rem;
  margin-left: 9rem;
}

.fab {
  color: var(--brand-dark-grey);
  font-size: 4rem;
  margin: 0 7rem;
  margin-top: 3rem;
}

.fab:first-of-type {
  margin-left: 0;
}

.social-media-wrapper-mobile {
  display: none;
}

#contact .contact-tel-mail a,
#contact .contact-tel-mail a:hover {
  color: var(--brand-dark-grey);
  text-decoration: none;
  font-weight: 400;
}

#contact .send-status-icon .krell-btn {
  font-size: 2.2rem;
  padding: 1.5rem 1rem;
  margin: 0 auto;
  width: auto;
}

#contact .send-wrapper .krell-btn {
  width: 23rem !important;
}

.send-status-icon {
  padding: 5rem 10vw;
  margin-left: 7rem;
}

#contact .send-status-icon p {
  font-size: 2.5rem;
  font-weight: 700;
}

.send-status-icon lord-icon {
  height: 100%;
  width: 100%;
}

.map {
  height: 90vh;
}

.contact-form {
  margin-left: 7rem;
}

.contact-send-icon {
  width: 7rem !important;
}

.input100 {
  font-family: "Rubik", sans-serif;
  font-size: 1.7rem;
  color: #8f8f8f;
  line-height: 1.2;

  display: block;
  width: 100%;
  height: 5.5rem;
  background: white;
  padding: 1.5rem;
  border: 0.2rem solid var(--brand-beige);
  border-radius: 0.5rem;
}

.wrap-email-input.wrap-input100 {
  margin-bottom: 7rem;
}

.wrap-email-input.alert-validate::before {
  top: 8rem;
}

textarea.input100 {
  height: 20rem;
}

input[type="checkbox"].input100 {
  height: auto;
  width: auto;
  display: inline-block;
}

.focus-input100 {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
  white-space: nowrap;
}

*:focus {
  outline: none;
}

.focus-input100::after {
  font-family: "Rubik", sans-serif;
  font-size: 2rem;
  color: var(--akzent-grey);
  line-height: 1;

  content: attr(data-placeholder);
  display: block;
  width: 100%;
  position: absolute;
  top: 1.6rem;
  font-weight: 600;
  left: 0rem;
  margin-left: 1.5rem;
  padding: 0 1rem;
  width: min-content;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}

.input100:focus + .focus-input100::after {
  top: -0.8rem;
  color: var(--akzent-grey);
  background-color: white;
}

.input100:focus + .focus-input100::before {
  width: 100%;
}

.input100:focus {
  color: var(--akzent-grey);
}

.has-val {
  color: var(--akzent-grey);
}

.has-val.input100 + .focus-input100::after {
  top: -0.8rem;
  color: var(--akzent-grey);
  background-color: white;
}

.has-val.input100 + .focus-input100::before {
  width: 100%;
}

.validate-input {
  position: relative;
}

.alert-validate input,
.alert-validate textarea {
  border: 0.2rem solid var(--error);
}

.datenschutz-control.alert-validate {
  border: unset;
}

.alert-validate::before {
  z-index: 2;
  content: attr(data-validate);
  position: absolute;
  top: 7.5rem;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 1rem;
  pointer-events: none;

  font-family: "Rubik", sans-serif;
  color: var(--error);
  font-size: 2rem;
  line-height: normal;
  text-align: left;

  -webkit-transition: opacity 0.4s;
  -o-transition: opacity 0.4s;
  -moz-transition: opacity 0.4s;
  transition: opacity 0.4s;
}

.alert-validate.message-area textarea {
  margin-bottom: 6rem;
}

.alert-validate.message-area::before {
  top: 22.5rem;
}

.alert-validate.datenschutz-control {
  margin-top: 2rem;
}

.alert-validate.datenschutz-control::before {
  top: -1rem;
  left: 2.5rem;
  width: 100%;
  font-size: 1.7rem;
}

#messageHelp {
  display: block;
  font-size: 1.5rem;
}

.datenschutz-control {
  font-size: 1.8rem;
  font-weight: 300;
}

.message-area {
  margin-bottom: 2.5rem;
}

.datenschutz-control-wrapper {
  margin-bottom: 5rem;
}

.wrap-input100 {
  width: 100%;
  position: relative;
  margin-bottom: 6rem;
}

.wrap-input100-xs {
  width: 100%;
  position: relative;
  margin-bottom: 6rem;
}

#contact .datenschutz {
  font-size: 2.2rem;
}

@media screen and (max-width: 96.875em) {
  .contact-form {
    margin-bottom: 5rem;
    width: 45vw;
  }
}

@media screen and (max-width: 85.3125em) {
  .contact-form {
    width: 40vw;
    margin-top: 5rem;
  }
}

@media screen and (max-width: 76.5625em) {
  .map {
    width: 100vw;
    object-fit: cover;
    object-position: center;
  }

  .send-status-icon {
    margin: 5rem auto;
  }

  .send-status-icon lord-icon {
    width: 20rem;
    height: 20rem;
  }

  .contact-form {
    margin-bottom: 5rem;
    width: 100vw;
  }
}

@media screen and (max-width: 68.75em) {
  .contact-form {
    margin: 2rem 6rem;
  }

  .contact-data {
    padding: 7rem 6rem;
    margin-left: 2rem;
  }
}

@media screen and (max-width: 50em) {
  .alert-validate::before {
    font-size: 1.7rem;
  }

  .wrap-email-input {
    margin-bottom: 8rem;
  }

  .wrap-email-input.alert-validate::before {
    top: 8.5rem;
  }

  .send-status-icon {
    margin: 5rem;
  }

  .fab {
    margin: 0 5rem;
  }
}

@media screen and (max-width: 40.625em) {
  .address-wrapper,
  .contact-tel-mail-wrapper {
    width: 100vw;
  }

  .contact-tel-mail-wrapper {
    margin-top: 1rem;
  }

  .fab {
    font-size: 3rem;
    margin: 0 3rem;
  }

  .social-media-wrapper-mobile {
    display: block;
    margin-top: 3rem;
  }

  .social-media-wrapper-desktop {
    display: none;
  }
}

@media screen and (max-width: 37.5em) {
  .contact-form {
    margin: 2rem 1rem;
  }
  .map {
    height: 70vh;
  }

  .datenschutz-control,
  #contact .datenschutz-control a {
    font-size: 1.7rem;
  }
}

@media screen and (max-width: 33.125em) {
  .contact-data {
    padding: 6rem 2rem 3rem 2rem;
    margin: 0;
  }
}

@media screen and (max-width: 31.25em) {
  .send-status-icon {
    margin: 2rem;
  }
}

@media screen and (max-width: 25.9375em) {
  .alert-validate.datenschutz-control {
    margin-top: 4rem;
  }

  .datenschutz-control.alert-validate::before {
    top: -2.5rem;
  }
}
</style>

<template>
  <section id="aboutme">
    <div id="aboutme-desktop">
      <span class="background-headline">Über Mich</span>
      <div class="am-wrapper">
        <img class="am-img box-shadow" src="/assets/inna-krell.jpg" />
        <div class="am-container">
          <h2>Meine persönliche Vorstellung</h2>
          <p>
            Mein Name ist Inna Krell, geboren 1984 und im Februar 2016 wurde ich
            zur Steuerberaterin bestellt. Meine ersten steuerlichen Erfahrungen
            konnte ich im Jahr 2001 mit der Ausbildung zur
            Steuerfachangestellten in einer kleinen Kanzlei in Regensburg
            sammeln. Über die Jahre hinweg bildete ich mich stetig weiter,
            zunächst zur Bilanzbuchhalterin, bevor ich im Jahr 2015 vor der
            Steuerberaterkammer Nürnberg meine Prüfung zur Steuerberaterin mit
            Erfolg ablegte. Meinen Beruf übe ich mit viel Leidenschaft aus, weil
            ich Menschen gerne dabei helfe komplexe Sachverhalte zu verstehen um
            daraus den besten Nutzen zu erzielen. Gerne lerne ich Sie in einem
            persönlichen Gespräch kennen und freue mich auf eine Nachricht von
            Ihnen.
          </p>
        </div>
      </div>
    </div>
    <div id="aboutme-mobile">
      <span class="background-headline">Über Mich</span>
      <div class="am-wrapper">
        <h2>Meine persönliche Vorstellung</h2>
        <p class="am-container">
          <img class="am-img box-shadow" src="/assets/inna-krell.jpg" />
          Mein Name ist Inna Krell, geboren 1984 und im Februar 2016 wurde ich
          zur Steuerberaterin bestellt. Meine ersten steuerlichen Erfahrungen
          konnte ich im Jahr 2001 mit der Ausbildung zur Steuerfachangestellten
          in einer kleinen Kanzlei in Regensburg sammeln. Über die Jahre hinweg
          bildete ich mich stetig weiter, zunächst zur Bilanzbuchhalterin, bevor
          ich im Jahr 2015 vor der Steuerberaterkammer Nürnberg meine Prüfung
          zur Steuerberaterin mit Erfolg ablegte. Meinen Beruf übe ich mit viel
          Leidenschaft aus, weil ich Menschen gerne dabei helfe komplexe
          Sachverhalte zu verstehen um daraus den besten Nutzen zu erzielen.
          Gerne lerne ich Sie in einem persönlichen Gespräch kennen und freue
          mich auf eine Nachricht von Ihnen.
        </p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "AboutMe",
};
</script>

<style lang="css" scoped>
#aboutme-desktop {
  height: 100%;
}

#aboutme-mobile {
  display: none;
}

#aboutme-desktop,
#aboutme-mobile {
  position: relative;
}

.am-img {
  margin: 9rem;
  width: 16vw;
  height: 100%;
  border-radius: 25px;
}

.am-container {
  width: 45vw;
}

.am-wrapper {
  display: flex;
  align-items: center;
  padding-top: 28rem;
}

@media screen and (max-width: 93.75em) {
  .am-img {
    width: 20vw;
  }
}

@media screen and (max-width: 68.75em) {
  #aboutme-desktop {
    display: none;
  }

  #aboutme-mobile {
    display: inline-block;
  }

  .am-wrapper {
    display: inline-block;
    padding: 6rem 6rem;
    width: 100%;
  }

  .am-container {
    width: 100%;
  }

  .am-img {
    margin: 0 2rem 2rem 0;
    float: left;
    width: 20rem;
  }
}

@media screen and (max-width: 33.125em) {
  .am-wrapper {
    padding: 6rem 2rem;
    width: 100%;
  }
}

@media screen and (max-width: 25em) {
  .am-img {
    width: 44vw;
    margin-right: 1rem;
    margin-bottom: 1rem;
  }
}
</style>

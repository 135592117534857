<template>
  <footer id="footer">
    <span class="foo-text">
      Inna Krell &copy; {{ new Date().getFullYear() }}
    </span>
    <a class="foo-straw-link" target="_blank" href="https://strawbinary.de/"
      ><img class="foo-img" src="/assets/MADE_BY_STRAWBINARY.svg"
    /></a>
    <div class="foo-wrapper">
      <a class="foo-wrapper-dts" href="datenschutz">Datenschutz</a>
      <a href="impressum">Impressum</a>
    </div>
  </footer>
</template>

<script>
export default { name: "Footer" };
</script>

<style scoped>
#footer {
  background-color: var(--akzent-grey);
  color: var(--white);
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-flow: row wrap;
  margin-left: 7rem;
  padding: 0 7rem;
  height: 8rem;
}

#footer p {
  margin: unset;
}

#footer .foo-wrapper a {
  color: var(--white);
  text-decoration: none;
}

#footer .foo-wrapper a:hover {
  text-decoration: underline;
}

.foo-wrapper-dts {
  margin-right: 5rem;
}

.foo-straw-link {
  justify-content: center;
  display: flex;
  margin-left: 7rem;
  width: 22rem;
}

.foo-img {
  width: 100%;
}

.foo-text,
#footer .foo-wrapper a {
  font-size: 2.5rem;
  font-weight: 300;
}

@media screen and (max-width: 68.75em) {
  #footer {
    height: 17rem;
    width: 100%;
    margin: 0;

    padding: 2rem 0;
    flex-flow: column wrap;
  }

  .foo-img {
    width: 100%;
  }

  .foo-straw-link {
    margin: 0;
    width: 24rem;
    order: 3;
  }

  .foo-wrapper {
    display: flex;
    justify-content: space-around;
    width: 70%;
    flex-flow: row wrap;
  }

  #footer .foo-wrapper a {
    min-width: min-content;
    font-weight: 500;
  }

  .foo-wrapper-dts {
    margin: 0;
  }

  .foo-text,
  #footer .foo-wrapper a {
    font-size: 1.8rem;
  }
}

@media screen and (max-width: 25em) {
  .foo-straw-link {
    width: 55%;
  }

  .foo-wrapper {
    width: 100%;
  }
}
</style>

<template>
  <a href="#" id="btnTop" class="box-shadow"><span class="arrow"></span></a>
</template>

<script>
export default {
  name: "scroll-to-top",
  mounted() {
    var btnTop = document.getElementById("btnTop");
    window.onscroll = () => {
      if (
        document.body.scrollTop > 60 ||
        document.documentElement.scrollTop > 60
      ) {
        btnTop.style.display = "flex";
      } else {
        btnTop.style.display = "none";
      }
    };
  },
};
</script>

<style lang="css" scoped>
#btnTop {
  display: none;
  position: fixed;
  bottom: 5rem;
  right: 5rem;
  padding: 2rem;
  z-index: 99;
  background-color: var(--white);

  cursor: pointer;
  border-radius: 50%;
  text-decoration: none;
  transform: rotate(45deg);
}

.arrow {
  height: 2rem;
  width: 2rem;
  border-top: 3px solid black;
  border-left: 3px solid black;
  transform: translate(0.4rem, 0.4rem);
}

@media screen and (max-width: 33.125em) {
  #btnTop {
    padding: 1.5rem;
    bottom: 3rem;
    right: 3rem;
  }

  .arrow {
    height: 1.5rem;
    width: 1.5rem;
    border-top: 2px solid black;
    border-left: 2px solid black;
    transform: translate(0.3rem, 0.3rem);
  }
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"nav-desktop"}},[_vm._m(0),_c('div',{staticClass:"bg-nav h-100"},[(this.$route.name === 'Home')?_c('div',{staticClass:"dots-align"},[_c('a',{staticClass:"dot-wrapper",attrs:{"href":"#aboutme"},on:{"click":function($event){_vm.selectedDot = 'dot1'}}},[_c('div',{staticClass:"dot",class:{
            'dot-orange-selected': _vm.selectedDot === 'dot1',
            'dot-dark': _vm.selectedDot !== 'dot1',
          },on:{"click":function($event){_vm.selectedDot = 'dot1'}}}),_c('div',{staticClass:"dot-info-text box-shadow"},[_vm._v(" Über mich ")])]),_c('a',{staticClass:"dot-wrapper",attrs:{"href":"#services"},on:{"click":function($event){_vm.selectedDot = 'dot2'}}},[_c('div',{staticClass:"dot",class:{
            'dot-orange-selected': _vm.selectedDot === 'dot2',
            'dot-dark': _vm.selectedDot !== 'dot2',
          },on:{"click":function($event){_vm.selectedDot = 'dot2'}}}),_c('div',{staticClass:"dot-info-text box-shadow"},[_vm._v(" Leistungen ")])]),_c('a',{staticClass:"dot-wrapper",attrs:{"href":"#contact"},on:{"click":function($event){_vm.selectedDot = 'dot3'}}},[_c('div',{staticClass:"dot",class:{
            'dot-orange-selected': _vm.selectedDot === 'dot3',
            'dot-dark': _vm.selectedDot !== 'dot3',
          },on:{"click":function($event){_vm.selectedDot = 'dot3'}}}),_c('div',{staticClass:"dot-info-text box-shadow"},[_vm._v(" Kontakt ")])])]):_vm._e()])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a',{staticClass:"nav-logo box-shadow",attrs:{"href":"/"}},[_c('img',{attrs:{"src":"/assets/Inna_Krell_Logo3.png","alt":"Inna Krell Logo"}})])}]

export { render, staticRenderFns }
<template>
  <div id="app">
    <Nav />
    <ScrollToTopBtn />
    <router-view :key="$route.fullPath"></router-view>
  </div>
</template>
<script>
import Nav from "./components/Nav.vue";
import ScrollToTopBtn from "./components/ScrollToTopBtn.vue";
export default {
  name: "KrellSteuerberatungWebsite",
  components: {
    Nav,
    ScrollToTopBtn,
  },
};
</script>

<style></style>

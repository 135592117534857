<template>
  <section id="services">
    <span class="background-headline">Leistungen</span>
    <div class="services-container">
      <h2>Meine Leistungen</h2>
      <p class="services-text">
        Ich berate mittelständische Mandanten unterschiedlicher Branchen und
        Rechtsformen sowie Privatpersonen und begleite sie zuverlässig,
        partnerschaftlich und sicher in allen steuerlichen und
        betriebswirtschaftlichen Belangen.
      </p>

      <div class="card-position">
        <div class="row">
          <div class="col card-wrapper">
            <div class="card-form-type">
              <div class="card-text-align box-shadow">
                <div class="card-headline">
                  <lord-icon
                    class="services-lordicon"
                    target="div"
                    trigger="hover"
                    src="./assets/CTServices_assets/979-project-estimate-outline-edited.json"
                  ></lord-icon>
                  <h3>Steuererklärung</h3>
                </div>
                <ul class="card-list">
                  <li>Einkommensteuer</li>
                  <li>Körperschaftsteuer</li>
                  <li>Umsatzsteuer</li>
                  <li>Gewerbesteuer</li>
                  <li>Erbschaft- und Schenkungsteuer</li>
                  <li>Bewertungen</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col card-wrapper">
            <div class="card-form-type">
              <div class="card-text-align box-shadow">
                <div class="card-headline">
                  <lord-icon
                    class="services-lordicon"
                    target="div"
                    trigger="hover"
                    src="./assets/CTServices_assets/999-money-check-outline-edited.json"
                  ></lord-icon>
                  <h3>Lohnbuchführung</h3>
                </div>
                <ul class="card-list">
                  <li>Lohn- und Gehaltsabrechnungen</li>
                  <li>Meldungen nach Sozialversicherungsrecht</li>
                  <li>Statistiken</li>
                  <li>Begleitung von Prüfungen</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col card-wrapper ">
            <div class="card-form-type">
              <div class="card-text-align box-shadow">
                <div class="card-headline">
                  <lord-icon
                    class="services-lordicon"
                    target="div"
                    trigger="hover"
                    src="./assets/CTServices_assets/755-invoice-receipt-validating-ticket-outline-edited.json"
                  ></lord-icon>
                  <h3>Finanzbuchhaltung</h3>
                </div>
                <ul class="card-list">
                  <li>Erstellen der Buchführung</li>
                  <li>Anlagenbuchführung</li>
                  <li>Betriebswirtschaftliche Auswertungen</li>
                  <li>Branchenvergleiche</li>
                  <li>Umsatzsteuer&shy;voranmeldungen</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col card-wrapper ">
            <div class="card-form-type">
              <div class="card-text-align box-shadow">
                <div class="card-headline">
                  <lord-icon
                    class="services-lordicon"
                    target="div"
                    trigger="hover"
                    src="./assets/CTServices_assets/28-calendar-outline-edited.json"
                  ></lord-icon>
                  <h3>Jahresabschluss</h3>
                </div>
                <ul class="card-list">
                  <li>Einnahmen-Überschuss-Rechnung</li>
                  <li>Einzelunternehmen</li>
                  <li>Personengesellschaften</li>
                  <li>Kapitalgesellschaften</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col card-wrapper ">
            <div class="card-form-type">
              <div class="card-text-align box-shadow">
                <div class="card-headline">
                  <lord-icon
                    class="services-lordicon"
                    target="div"
                    trigger="hover"
                    src="./assets/CTServices_assets/981-consultation-outline-edited.json"
                  ></lord-icon>
                  <h3>Beratung</h3>
                </div>
                <ul class="card-list">
                  <li>Existenzgründung</li>
                  <li>Rechtsformenwahl</li>
                  <li>Investitionsrechnung</li>
                  <li>Finanzierungsberechnung</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col card-wrapper">
            <div class="card-form-type">
              <div class="card-text-align box-shadow">
                <div class="card-headline">
                  <lord-icon
                    class="services-lordicon"
                    target="div"
                    trigger="hover"
                    src="./assets/CTServices_assets/945-dividends-outline-edited.json"
                  ></lord-icon>
                  <h3>Steuerliche Beratung</h3>
                </div>
                <ul class="card-list">
                  <li>Prüfung von Steuerbescheiden</li>
                  <li>Rechtsbehelfsverfahren</li>
                  <li>Teilnahme an Betriebsprüfungen</li>
                  <li>Verhandlungen mit Behörden</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Services",
};
</script>

<style scoped>
#services {
  height: 100%;
  position: relative;
}

.services-container {
  padding-top: 28rem;
  display: flex;
  flex-flow: column nowrap;
  align-content: center;
  margin-left: 9rem;
}

.services-text {
  width: 60%;
}

.services-lordicon {
  margin: 0 2rem 0 1rem;
  height: 100%;
  max-width: 20%;
  min-width: 20%;
}

.card-position {
  justify-content: center;
  margin-top: 4rem !important;
}

.row .card-wrapper:first-child .card-form-type {
  margin-left: 0;
}

.row .card-wrapper:last-child .card-form-type {
  margin-right: 0;
}

.card-wrapper {
  margin-bottom: 3rem !important;
}

.card-form-type {
  margin: 2rem;
  background-color: var(--white);
  height: 57rem;
}

.card-text-align {
  height: 100%;
  padding: 0 1.5rem;
  padding-bottom: 1.5rem;
}

.card-headline {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-top: 2rem;
}

.card-list {
  word-break: break-word;
  hyphens: auto;
  padding-top: 2rem;
  padding-left: 6rem;
}

@media screen and (max-width: 93.75em) {
  .card-form-type {
    height: 36rem;
  }

  .card-list {
    padding-left: 5rem;
  }
}

@media screen and (max-width: 70.8125em) {
  .card-wrapper {
    margin: 1rem 1rem;
  }

  .services-lordicon {
    margin: 0 0rem 0 1rem;
  }
}

@media screen and (max-width: 68.75em) {
  .services-container {
    padding: 7rem 6rem;
    margin: 0;
  }

  .services-text {
    width: 100%;
  }

  .services-lordicon {
    margin: 0;
  }

  .card-form-type {
    width: 80vw;
    height: 100%;
    max-width: 100%;
    min-width: unset;
    margin-left: auto;
    margin-right: auto;
  }

  .card-wrapper {
    margin-bottom: 2rem !important;
  }

  .row .card-wrapper:first-child .card-form-type {
    margin-left: auto;
  }
  .row .card-wrapper:last-child .card-form-type {
    margin-right: auto;
  }
}

@media screen and (max-width: 33.125em) {
  .services-container {
    padding: 7rem 2rem;
  }

  .card-form-type {
    width: 100vw;
  }

  .card-headline {
    justify-content: center;
    flex-direction: column;
    padding-top: 1rem;
  }

  .card-list {
    padding-left: 3rem;
  }

  .card-headline h3 {
    text-align: center;
  }

  .card-list * {
    font-size: 1.8rem;
    font-weight: 400;
    line-height: 2.7rem;
  }

  .services-lordicon {
    max-width: 25%;
    min-width: 25%;
  }
}

@media screen and (max-width: 20.625em) {
  .card-text-align {
    padding: 0 1rem;
    padding-bottom: 1rem;
  }
}
</style>

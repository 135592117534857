import Vue from "vue";
import App from "./App.vue";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { loadAnimation } from "lottie-web";
import { defineLordIconElement } from "lord-icon-element";
import router from "./router";
import Vuelidate from "vuelidate";
import VueMeta from "vue-meta";
Vue.use(Vuelidate);
Vue.use(VueMeta);

defineLordIconElement(loadAnimation);

Vue.config.productionTip = false;

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");

<template>
  <div id="nav-desktop">
    <a href="/" class="nav-logo box-shadow">
      <img src="/assets/Inna_Krell_Logo3.png" alt="Inna Krell Logo" />
    </a>
    <div class="bg-nav h-100">
      <div class="dots-align" v-if="this.$route.name === 'Home'">
        <a
          href="#aboutme"
          class="dot-wrapper"
          v-on:click="selectedDot = 'dot1'"
        >
          <div
            class="dot"
            :class="{
              'dot-orange-selected': selectedDot === 'dot1',
              'dot-dark': selectedDot !== 'dot1',
            }"
            v-on:click="selectedDot = 'dot1'"
          ></div>
          <div class="dot-info-text box-shadow">
            Über mich
          </div>
        </a>
        <a
          href="#services"
          class="dot-wrapper"
          v-on:click="selectedDot = 'dot2'"
        >
          <div
            class="dot"
            :class="{
              'dot-orange-selected': selectedDot === 'dot2',
              'dot-dark': selectedDot !== 'dot2',
            }"
            v-on:click="selectedDot = 'dot2'"
          ></div>
          <div class="dot-info-text box-shadow">
            Leistungen
          </div>
        </a>
        <a
          href="#contact"
          class="dot-wrapper"
          v-on:click="selectedDot = 'dot3'"
        >
          <div
            class="dot"
            :class="{
              'dot-orange-selected': selectedDot === 'dot3',
              'dot-dark': selectedDot !== 'dot3',
            }"
            v-on:click="selectedDot = 'dot3'"
          ></div>
          <div class="dot-info-text box-shadow">
            Kontakt
          </div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Nav",
  data() {
    return {
      selectedDot: "",
      current: "",
      posAboutMe: 0,
      posServices: 0,
      posContact: 0,
      scrollPosition: 0,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.updateScroll);
    this.posAboutMe = document
      .getElementById("aboutme")
      .getBoundingClientRect().top;
    this.posServices = document
      .getElementById("services")
      .getBoundingClientRect().top;
    this.posContact = document
      .getElementById("contact")
      .getBoundingClientRect().top;
  },
  methods: {
    updateScroll() {
      this.scrollPosition = window.scrollY;
      if (this.scrollPosition >= this.posAboutMe - 100) {
        this.selectedDot = "dot1";
      }
      if (this.scrollPosition <= this.posAboutMe - 100) {
        this.selectedDot = "";
      }
      if (this.scrollPosition >= this.posServices) {
        this.selectedDot = "dot2";
      }
      if (this.scrollPosition >= this.posContact) {
        this.selectedDot = "dot3";
      }
    },
  },
};
</script>

<style lang="css" scoped>
#nav-desktop {
  position: fixed;
  z-index: 1999;
  left: 0;
  top: 0;
  height: 100%;
  width: 7rem;
  overflow-x: visible;
}

.nav-logo {
  position: absolute;
  width: 22.7rem;
  height: 12.4rem;
  left: 0;
  top: 3.7rem;
  background-color: var(--white);
}

.nav-logo img {
  height: 10rem;
  width: 10rem;
  margin: 1.2rem 0;
  left: 10rem;
  position: absolute;
}

.dots-align {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  height: 100%;
}

.bg-nav {
  position: relative;
  background-color: var(--white);
  box-shadow: 0px -2px 188px rgba(27, 27, 27, 0.07),
    0px -1.18691px 86.9181px rgba(27, 27, 27, 0.0519173),
    0px -0.959463px 49.7326px rgba(27, 27, 27, 0.0438747),
    0px -0.818658px 30.1873px rgba(27, 27, 27, 0.0377964),
    0px -0.686152px 18.1892px rgba(27, 27, 27, 0.0322036),
    0px -0.524221px 10.1289px rgba(27, 27, 27, 0.0261253),
    0px -0.304838px 4.35637px rgba(27, 27, 27, 0.0180827);
}

.dot-wrapper {
  display: flex;
  align-items: center;
  position: relative;
  margin: 4rem 0;
  padding: 0 4rem;
}

.dot {
  width: 2.5rem;
  height: 2.5rem;
}

.dot-dark {
  background-image: url("/assets/Dot-dark.svg");
}

.dot-dark:hover {
  background-image: url("/assets/Dot-filled-dark.svg");
  cursor: pointer;
}

.dot-orange-selected {
  background-image: url("/assets/Dot-filled-orange.svg");
}

.dot-orange-selected:hover {
  background-image: url("/assets/Dot-filled-orange.svg");
  cursor: pointer;
}

.dot-wrapper:hover .dot-info-text {
  display: unset;
}

.dot-wrapper:hover.dot-orange-selected {
  background-image: url("/assets/Dot-filled-orange.svg");
}

.dot-wrapper:hover .dot-dark {
  background-image: url("/assets/Dot-filled-dark.svg");
}

.dot-info-text:hover {
  display: block;
}

.dot-info-text {
  display: none;
  left: 8.7rem;
  position: absolute;
  width: max-content;
  padding: 1.4rem;
  padding-right: 2.5rem;

  font-size: 3.2rem;
  font-weight: 600;
  line-height: 4.8rem;
  text-transform: uppercase;

  color: var(--brand-dark-grey);
  background-color: var(--white);

  z-index: -1;
}

@media screen and (max-width: 68.75em) {
  .dots-align,
  .bg-nav {
    display: none;
  }

  .nav-logo {
    width: 11.7rem;
    height: 8.4rem;
  }

  .nav-logo img {
    height: 7rem;
    width: 7rem;
    margin: 0.7rem 0;
    left: 3rem;
  }
}

@media screen and (max-width: 25em) {
  .nav-logo {
    width: 9rem;
    height: 7.4rem;
    top: 1.6rem;
  }

  .nav-logo img {
    height: 5.4rem;
    width: 5.4rem;
    margin: 1rem 0;
    left: 2rem;
  }
}
</style>

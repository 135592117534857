<template>
  <section id="landingsection">
    <div class="row">
      <div class="col">
        <div class="ls-img box-shadow"></div>
      </div>
      <div class="col ls-wrapper">
        <div class="ls-logo-right"></div>
        <div class="ls-container">
          <h2 id="ls-headline">Denken, Fühlen, gemeinsam Ziele erreichen</h2>
          <p>
            VERTRAUEN wird bei mir groß geschrieben. Zu meinen Mandanten pflege
            ich ein vertrauensvolles und persönliches Verhältnis und unterstütze
            sie bei der individuellen Steuerberatung und Steuerplanung. Dies
            trifft sowohl die Betreuung von klein- und mittelständischen
            Unternehmen sowie auch von natürlichen Personen.
          </p>
          <a class="krell-btn" href="#contact">
            <label>Kontakt</label
            ><lord-icon
              class="ls-btn-arrow"
              target="a"
              trigger="hover"
              src="./assets/225-arrow-14-edited.json"
            ></lord-icon>
          </a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default { name: "Landingsection" };
</script>

<style scoped>
#landingsection {
  margin-bottom: 7rem;
  height: calc(var(--vh, 1vh) * 100);
}

#ls-headline {
  font-weight: 400;
  font-size: 5.2rem;
  line-height: 7.6rem;
  padding-bottom: unset;
}

#landingsection .krell-btn {
  color: white;
  text-decoration: none;
}

#landingsection .krell-btn:hover {
  color: white;
  text-decoration: none;
}

.ls-img {
  background-image: url("/assets/law-firm-4924547_1920.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: calc(var(--vh, 1vh) * 100);
}

.ls-logo-right {
  position: relative;
  left: 8rem;
  background-image: url("/assets/Inna_Krell_Logo.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
  height: 20vh;
  width: 100%;
}

.ls-container {
  height: 70vh;
  padding: 4vh 5vw 0 5vw;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.ls-btn-arrow {
  transform: rotate(90deg);
}

@media screen and (max-width: 93.75em) {
  #ls-headline {
    font-size: 3.8rem;
    line-height: 5.3rem;
  }

  .ls-container {
    padding: 2vh 0 0 5vw;
  }
}

@media screen and (max-width: 68.75em) {
  #landingsection {
    height: min-content;
    margin-bottom: 0;
  }

  .ls-img {
    height: 27vh;
    min-width: 100%;
    background-position-x: unset;
    background-position-y: center;

    z-index: -1;
    position: absolute;
    filter: none;
  }

  .ls-container {
    height: 100%;
    min-height: 40rem;
    padding: 2vh 18vw;
  }

  .ls-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-width: 100%;
    padding-top: 13vh !important;
    padding-bottom: 13vh !important;
  }

  .ls-logo-right {
    position: unset;
    left: 0;
    height: 38vh;
    background-position: center;
  }
}

@media screen and (max-width: 46.875em) {
  #landingsection {
    height: unset;
  }

  #ls-headline {
    font-size: 3.2rem;
    line-height: 4.2rem;
  }

  .ls-container {
    padding: 3vh 8vw;
    padding-top: 0;
  }

  .ls-wrapper {
    padding-top: 7vh !important;
    padding-bottom: 7vh !important;
  }

  .ls-btn-arrow {
    height: 6rem;
    width: 6rem;
  }
  .ls-img {
    height: 20vh;
  }

  .ls-logo-right {
    height: 32vh;
  }
}

@media screen and (max-width: 25em) {
  #ls-headline {
    font-size: 2.5rem;
    line-height: 3rem;
  }

  .ls-container {
    padding: 0 3rem;
    padding-top: unset;
  }

  .ls-wrapper {
    padding-top: 8vh !important;
    padding-bottom: 8vh !important;
  }
}

@media screen and (max-height: 43.75em) {
  .ls-container {
    height: 100%;
  }
}
</style>

<template>
  <div>
    <div id="impressum">
      <div class="head-container">
        <h1 class="headline">Impressum</h1>
        <img class="logo" src="assets/Inna_Krell_Logo_Inverted.svg" />
      </div>
      <div class="text-container">
        <h2>Angaben gem&auml;&szlig; &sect; 5 TMG</h2>
        <p>
          Inna Krell<br />
          Maximilianstr. 9<br />
          93142 Maxh&uuml;tte-Haidhof
        </p>

        <h2>Kontakt</h2>
        <p>
          Telefon: 09471 / 6009779<br />
          E-Mail: krell@krell-steuer.de
        </p>

        <h2>Berufsbezeichnung und berufsrechtliche Regelungen</h2>
        <p>
          Berufsbezeichnung: Steuerberater<br />
          Zust&auml;ndige Kammer: Steuerberaterkammer N&uuml;rnberg<br />
          Verliehen in: Deutschland
        </p>
        <p>Es gelten folgende berufsrechtliche Regelungen:</p>
        <p>
          Alle s&auml;mtliche Regelungen<br />
          einsehbar unter:
          <a
            href="http://www.stbk-muc/downloads"
            target="_blank"
            rel="noopener noreferrer"
            >http://www.stbk-muc/downloads</a
          >
        </p>
        <h2>Angaben zur Berufs&shy;haftpflicht&shy;versicherung</h2>
        <p>
          <strong>Name und Sitz des Versicherers:</strong><br />
          Allianz Versicherungs-Aktiengesellschaft<br />
          K&ouml;niginstr. 28<br />
          80802 M&uuml;nchen
        </p>
        <p><strong>Geltungsraum der Versicherung:</strong><br />EU-weit</p>

        <h2>Redaktionell verantwortlich</h2>
        <p>
          Inna Krell<br />
          Maximilianstr. 9<br />
          93142 Maxh&uuml;tte-Haidhof<br />
          09471/6009779<br />
          krell@krell-steuer.de
        </p>

        <h2>EU-Streitschlichtung</h2>
        <p>
          Die Europ&auml;ische Kommission stellt eine Plattform zur
          Online-Streitbeilegung (OS) bereit:
          <a
            href="https://ec.europa.eu/consumers/odr/"
            target="_blank"
            rel="noopener noreferrer"
            >https://ec.europa.eu/consumers/odr/</a
          >.<br />
          Unsere E-Mail-Adresse finden Sie oben im Impressum.
        </p>

        <h2>
          Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle
        </h2>
        <p>
          Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren
          vor einer Verbraucherschlichtungsstelle teilzunehmen.
        </p>

        <p>Quelle: <a href="https://www.e-recht24.de">e-recht24.de</a></p>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Footer from "../components/Footer.vue";
export default {
  name: "Impressum",
  components: {
    Footer,
  },
  metaInfo: {
    title: "Inna Krell Steuerberaterin | Impressum",
  },
};
</script>

<style></style>

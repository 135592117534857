<template>
  <div>
    <main id="main">
      <ContentTypeLandingsection />
      <ContentTypeAboutMe />
      <ContentTypeServices />
      <ContentTypeContact />
    </main>
    <Footer />
  </div>
</template>

<script>
import ContentTypeAboutMe from "../components/ContentTypeAboutMe.vue";
import ContentTypeLandingsection from "../components/ContentTypeLandingsection.vue";
import ContentTypeServices from "../components/ContentTypeServices.vue";
import ContentTypeContact from "../components/ContentTypeContact.vue";
import Footer from "../components/Footer.vue";
export default {
  name: "Home",
  components: {
    ContentTypeLandingsection,
    ContentTypeAboutMe,
    Footer,
    ContentTypeServices,
    ContentTypeContact,
  },
  metaInfo: {
    title: "Inna Krell Steuerberaterin",
  },
};
</script>
